.custom-slider {
    position: relative;
    & &__wrapper {
        position: relative;
        height: 130px;
        /* 
        Will be changed by js 
        At the beginning the slider has no arrow on the left
        */
        margin-left: 39px;
        margin-right: 76px;
        z-index: 999;
    }

    & &__heading {
        font-size: 55px;
        position: absolute;
        top: 50%;
        left: 0;
        right: auto;
        transform: translateY(-50%);
        transition: all 0.3s;
        text-transform: uppercase;
        user-select: none;
    }

    & &__heading:not(:first-child) {
        opacity: 0;
        display: none;
        left: auto;
        right: 0px;
        -webkit-text-stroke: 1px black;
        color: white;
    }

    & &__heading:nth-child(2) {
        display: block;
        opacity: 1;
    }

    & &__button-wrapper {
        user-select: none;

        &-prev {
            display: none;
        }

        img {
            width: 37.44px;
            height: 55.62px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
        }
    }

    & &__button-wrapper:hover {
        img:first-child {
            display: none;
        }

        img:last-child {
            display: block;
        }
    }

    & &__prev-button,
    & &__prev-button-hover {
        left: 50px;

        &-hover {
            display: none;
        }
    }

    & &__next-button,
    & &__next-button-hover {
        right: 50px;

        &-hover {
            display: none;
        }
    }
}
