:root {
    --small-phone-max-height: 500px;
    --small-phone-max-width: 350px;

    --phone-max-height: 550px;

    --tablet-max-width: 600px;
    --tablet-max-height: 700px;

    --desktop-max-width: 650px;
    --desktop-max-height: 750px;
}

/* Image gallery */
.image-gallery {
    display: none;

    * {
        user-select: none;
    }

    &--visible {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 9999;
        height: 100vh;
        min-height: 667px;
        width: 100vw;
        max-width: 100vw;
        display: grid;
        place-items: center;
        background-color: rgba(255, 255, 255, 0.95);
    }

    &__inner-wrapper {
        position: relative;
        max-height: var(--small-phone-max-height);
        max-width: var(--small-phone-max-width);

        @media (min-width: 390px) {
            max-height: var(--phone-max-height);
        }

        @media (--tablet) {
            max-width: var(--tablet-max-width);
            max-height: var(--tablet-max-height);
        }

        @media (--desktop) {
            max-width: var(--desktop-max-width);
            max-height: var(--desktop-max-height);
        }

        &--hidden {
            opacity: 0;
        }
    }

    &__close {
        position: absolute;
        top: -60px;
        height: 30px;
        width: 30px;
        right: 0;
        cursor: pointer;
    }

    .swiper-slide {
        display: flex;
        align-items: center;

        img {
            width: 100%;
        }
    }

    .top-swiper {
        position: relative;
        overflow: hidden;
        max-height: calc(var(--small-phone-max-height) - 90px);
        margin-bottom: 20px;

        @media (min-width: 390px) {
            max-height: calc(var(--phone-max-height) - 90px);
        }

        @media (--tablet) {
            max-height: calc(var(--tablet-max-height) - 90px);
        }

        @media (--desktop) {
            max-height: calc(var(--desktop-max-height) - 90px);
        }

        img {
            @media (--tablet) {
                max-height: calc(var(--tablet-max-height) - 90px);
                object-fit: cover;
            }

            @media (--desktop) {
                max-height: calc(var(--desktop-max-height) - 90px);
            }
        }

        .prev-btn,
        .next-btn {
            height: 60px;
            width: 60px;
            display: grid;
            place-items: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 999;
            cursor: pointer;

            img {
                height: 32px;
            }
        }

        .prev-btn {
            left: 15px;

            &--disabled {
                display: none;
            }
        }

        .next-btn {
            right: 15px;

            &--disabled {
                display: none;
            }
        }
    }

    .bottom-swiper {
        height: 70px;
        overflow: hidden;

        img {
            height: 70px;
            object-fit: cover;
        }
    }
}
