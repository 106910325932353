.footer {
    padding-top: 62px;
    padding-bottom: 73px;
    border-top: 1px solid $black;

    @media (--tablet) {
        padding-top: 92px;
    }

    & &__top-wrapper,
    & &__middle-wrapper,
    & &__bottom-wrapper {
        max-width: 1300px;
        margin: 0 auto;
        padding-left: 40px;
        padding-right: 40px;

        @media (--tablet) {
            padding-left: 85px;
            padding-right: 85px;
        }

        @media (--desktop) {
            padding-left: 90px;
            padding-right: 90px;
        }
    }

    &__top-wrapper {
        @media (--desktop) {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    & &__logo-wrapper {
        display: block;
        width: 162px;
        height: 77px;

        @media (--tablet) {
            width: 264px;
            height: 125px;
        }
    }

    & &__logo {
        width: 100%;
        height: 100%;
    }

    & &__logo-wrapper:hover &__logo {
        display: none;
    }

    & &__logo-hover {
        display: none;
    }

    & &__logo-wrapper:hover &__logo-hover {
        display: block;
    }

    & &__back-to-top-desktop {
        display: none;

        @media (--desktop) {
            display: block;
            font-size: 14px;
            letter-spacing: 0.2em;
            line-height: 18px;
            text-decoration: none;
            text-transform: uppercase;
            color: $black;
            font-weight: 700;

            &:hover {
                filter: invert(16%) sepia(90%) saturate(4361%)
                    hue-rotate(349deg) brightness(85%) contrast(111%);
            }
        }
    }

    & &__middle-wrapper {
        @media (--desktop) {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 106px;
        }
    }

    & &__links-wrappers {
        margin-top: 62px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        row-gap: 39px;

        @media (--tablet) {
            margin-top: 80px;
            display: flex;
            justify-content: space-between;
            column-gap: 85px;
        }

        @media (--desktop) {
            margin-top: 0px;
        }
    }

    & &__links-wrapper:nth-child(2) {
        grid-column: 2;
        grid-row: 1 / 3;
    }

    & &__links-caption {
        font-weight: 700;
        margin-bottom: 30px;
        text-transform: uppercase;
    }

    & &__links-caption,
    & &__link,
    & &__social-media-title {
        font-size: 10px;
        letter-spacing: 0.2em;
        line-height: 25px;
        font-weight: 700;

        @media (--tablet) {
            font-size: 14px;
        }
    }

    & &__link:not(:last-child) {
        margin-bottom: 0px;
    }

    & &__links-wrapper:last-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (--tablet) {
            display: block;
        }
    }

    & &__link {
        margin-bottom: 7px;
        font-weight: 500;
        white-space: nowrap;

        a {
            text-decoration: none;
            color: $black;
        }

        :hover {
            color: $red;
            font-style: italic;
        }
    }

    & &__social-media-wrapper {
        margin-top: 39px;

        @media (--tablet) {
            margin-top: 90px;
        }

        @media (--desktop) {
            margin-top: 0px;
        }
    }

    & &__social-media-title {
        text-transform: uppercase;
    }

    & &__social-media-icons {
        margin-top: 30px;
        display: flex;
        column-gap: 14px;

        @media (--tablet) {
            column-gap: 23px;
        }
    }

    & &__social-media-icon-wrapper:hover img:last-child {
        filter: invert(16%) sepia(90%) saturate(4361%) hue-rotate(349deg)
            brightness(85%) contrast(111%);
    }

    & &__social-media-icon {
        height: 44.68px;
        width: 44.68px;
        cursor: pointer;

        @media (--tablet) {
            height: 72.81px;
            width: 72.81px;
        }
    }

    & &__bottom-wrapper {
        @media (--tablet) {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        @media (--desktop) {
            display: block;
        }
    }

    & &__bottom-links-list {
        margin-top: 40px;

        @media (--tablet) {
            margin-top: 90px;
            display: inline-block;
        }

        @media (--desktop) {
            margin-top: 91px;
        }
    }

    & &__back-to-top {
        display: block;
        margin-top: 39px;
        font-size: 10px;
        letter-spacing: 0.2em;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        color: $black;

        @media (--tablet) {
            font-size: 14px;
        }

        @media (--desktop) {
            display: none;
        }
    }

    & &__bottom-links-list {
        @media (--desktop) {
            display: flex;
            column-gap: 36px;
            width: 100%;
            justify-content: space-between;
        }
    }
}
