/* Base */
@import "base/reset.css";
@import "base/colors.css";
@import "base/media.css";
@import "base/fonts.css";

/* Components */
@import "components/cta-button.css";
@import "components/media-button.css";
@import "components/mobile-nav.css";
@import "components/section-header.css";
@import "components/select.css";
@import "components/video-player.css";
@import "components/image-gallery.css";
@import "components/custom-slider.css";
@import "components/spinner.css";
@import "components/banner.css";

/* Layout */
@import "layout/header.css";
@import "layout/section.css";
@import "layout/radiator-section.css";
@import "layout/sanitary-section.css";
@import "layout/air-conditioning-section.css";
@import "layout/ventilation-section.css";
@import "layout/support-section.css";
@import "layout/funding-section.css";
@import "layout/team-section.css";
@import "layout/contact-section.css";
@import "layout/contact-and-not-found.css";

@import "layout/footer.css";
@import "layout/imprint.css";
@import "layout/privacy-policy-section.css";
@import "layout/history.css";

html,
* {
    font-family: $red-hant-font;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    -webkit-text-size-adjust: 100%;
}

.break-text-mobile {
    &::after {
        content: "";
        display: block;

        @media (min-width: 450px) {
            display: none;
        }
    }

    @media (min-width: 450px) {
        display: none;
    }
}

.break-text-web {
    display: none;

    @media (--desktop) {
        display: inline;
    }

    &::after {
        content: "";
        display: none;

        @media (--desktop) {
            display: inline-block;
        }
    }
}
