.support-section {
    background-color: $red;
    position: relative;

    & &__header {
        @media (--desktop) {
            max-width: 1280px;
            margin: 0 auto;
        }
    }

    & &__services-wrapper {
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 50px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 180px;

        @media (--tablet) {
            padding-top: 90px;
            padding-left: 85px;
            padding-right: 85px;
            padding-bottom: 483px;
        }

        @media (--desktop) {
            padding-left: 90px;
            padding-right: 90px;
            padding-bottom: 286px;
        }
    }

    & &__swiper-button-next {
        right: 15px;
        @media (--tablet) {
            right: 37px;
        }
    }

    & &__swiper-button-prev {
        left: 15px;
        @media (--tablet) {
            left: 37px;
        }
    }

    & &__swiper-button-next,
    & &__swiper-button-prev {
        height: 25.07px;
        width: 16.87px;
        top: 215px;

        @media (--tablet) {
            width: 37.44px;
            height: 55.62px;
            top: 330px;
        }
    }

    & .swiper-button-disabled {
        display: none;
    }

    & &__services-swiper {
        overflow: hidden;
    }

    .swiper-slide {
        &__title {
            font-size: 28px;
            font-weight: 700;
            letter-spacing: 0.05em;
            line-height: 32px;
            text-transform: uppercase;

            @media (--tablet) {
                font-size: 55px;
                line-height: 57px;
                margin-left: 46px;
            }

            @media (--desktop) {
                margin-left: 0px;
            }
        }

        &__button-next {
            right: 23px;
        }

        &__phone-wrapper {
            display: flex;
            align-items: center;
            column-gap: 15px;
            margin-top: 37px;
            cursor: pointer;
            text-decoration: none;
            color: $black;

            @media (--tablet) {
                margin-top: 52px;
                margin-left: 46px;
            }

            @media (--desktop) {
                margin-left: 0px;
            }
        }

        &__phone-wrapper:hover {
            color: $white;
        }

        &__phone-wrapper:hover .swiper-slide__phone-icon {
            display: none;
        }

        &__phone-wrapper:hover .swiper-slide__phone-icon-hover {
            display: block;
        }

        &__phone-icon,
        &__phone-icon-hover {
            width: 24.75px;
            height: 24.75px;

            @media (--tablet) {
                width: 51.5px;
                height: 51.51px;
            }
        }

        &__phone-icon-hover {
            display: none;
        }

        &__phone-text {
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.2em;
            font-weight: 700;

            @media (--tablet) {
                font-size: 18px;
            }
        }

        &__reachability-text {
            margin-top: 35px;

            @media (--tablet) {
                margin-top: 51px;
            }
        }

        &__benefits-description {
            margin-top: 37px;

            @media (--tablet) {
                margin-top: 51px;
            }
        }

        &__benefits-description,
        &__benefit-text,
        &__reachability-text {
            font-size: 16px;
            line-height: 22px;
            letter-spacing: 0.05em;

            @media (--tablet) {
                font-size: 20px;
                line-height: 25px;
            }

            @media (--desktop) {
                font-size: 18px;
            }
        }

        &__benefits-wrapper {
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            row-gap: 25px;
        }

        &__benefit-wrapper {
            display: flex;
            column-gap: 10px;
        }

        &__benefit-icon {
            margin-top: 3px;
            height: 15.6px;
            width: 16.21px;

            @media (--desktop) {
                margin-top: 5px;
            }
        }
    }

    &__img {
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;

        @media (--tablet) {
            width: 752px;
            height: 343px;
            margin: 0 auto;
        }
    }
}
