.banner-container {
    top: 0;
    left: 0;
    z-index: 99999;
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-rows: auto 1fr;
}

.banner {
    background-color: #fff;
    padding: 40px;
    height: 100vh;
    overflow-y: scroll;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

    @media (--tablet) {
        padding-bottom: 80px;
        padding-left: 85px;
        padding-right: 85px;
        height: auto;
    }

    @media (--desktop) {
        padding-left: 90px;
        padding-right: 90px;
        height: auto;
    }

    &__inner {
        max-width: 600px;

        @media (--desktop) {
            max-width: 1300px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            column-gap: 80px;
        }
    }

    &__left {
        @media (--desktop) {
            display: flex;
            column-gap: 50px;
        }
    }

    &__logo {
        width: 162px;
        height: 77px;
        margin-bottom: 40px;

        @media (--tablet) {
            width: 264px;
            height: 125px;
            margin-bottom: 60px;
        }

        @media (--desktop) {
            margin-bottom: 0;
        }
    }

    &__title {
        text-transform: uppercase;
        line-height: 18px;
        margin-bottom: 5px;

        @media (--desktop) {
            margin-bottom: 0;
        }
    }

    &__text {
        font-style: italic;
        line-height: 20px;
        margin-bottom: 50px;

        @media (--tablet) {
            margin-bottom: 60px;
        }

        @media (--desktop) {
            margin-bottom: 0;
        }
    }

    &__right {
        @media (--tablet) {
            display: flex;
            justify-content: space-between;
        }

        @media (--desktop) {
            flex-direction: column;
            align-self: flex-end;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        margin-bottom: 15px;

        @media (--tablet) {
            flex-direction: row;
            column-gap: 15px;
        }

        @media (--desktop) {
            flex-direction: column;
        }
    }

    &__social-icons {
        display: flex;
        justify-content: right;
        column-gap: 10px;
    }

    &__social-icon {
        height: 40px;
        width: 40px;
        cursor: pointer;

        &:hover {
            filter: invert(16%) sepia(90%) saturate(4361%) hue-rotate(349deg)
                brightness(85%) contrast(111%);
        }
    }
}

.banner-blur {
    background-color: rgba(48, 48, 48, 0.95);
}
