.mobile-nav-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    row-gap: 100px;
    padding: 51px 40px 32px 40px;
    inset: 15px 11px auto 11px;

    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(50px);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    transform: translateY(-800px);
    transition: all 200ms ease-out;

    @media (--tablet) {
        padding-top: 105px;
        padding-bottom: 72px;
        padding-left: 54px;
        padding-right: 54px;

        top: 34px;
        left: 32px;
        right: 32px;

        transform: translateY(-900px);
    }

    @media (--desktop) {
        display: none;
    }
}

.mobile-nav-wrapper--visible {
    transform: translateY(0px);

    @media (--tablet) {
        transform: translateY(0px);
    }
}

.mobile-menu-close {
    align-self: end;
    height: 46.18px;
    width: 46.18px;
    cursor: pointer;

    @media (--tablet) {
        height: 71.02px;
        width: 71.02px;
    }
}

.mobile-nav {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
}

.mobile-nav-link {
    font-size: 30px;
    letter-spacing: 0.05em;
    text-decoration: none;
    color: $black;
    font-weight: 700;
}
