.air-conditioning-section {
    background-color: $black;

    & &__inner-wrapper {
        padding: 50px 40px 90px 40px;

        @media (--tablet) {
            padding: 90px 85px 285px 85px;
        }

        @media (--desktop) {
            padding-bottom: 177px;
            max-width: 1280px;
            margin: 0 auto;
            position: relative;
        }
    }

    & &__subtitle {
        font-size: 28px;
        letter-spacing: 0.05em;
        line-height: 32px;
        font-weight: 700;
        color: $white;
        text-transform: uppercase;

        @media (--tablet) {
            font-size: 55px;
            line-height: 80px;
        }
    }

    & &__img-wrapper {
        margin-top: 74px;
        display: flex;
        align-items: center;
        column-gap: 62px;
        opacity: 1;

        @media (min-width: 650px) {
            position: absolute;
            bottom: 50px;
            right: 61px;

            &--visible {
                opacity: 0.4;
            }
        }

        @media (--tablet) {
            bottom: 130px;

            &--visible {
                opacity: 0.4;
            }
        }

        @media (--desktop) {
            bottom: 114px;
            right: 125px;
            column-gap: 22px;

            &--visible {
                opacity: 1;
            }
        }
    }

    &__img-inner,
    &__img-outter {
        position: absolute;
    }

    &__img-inner,
    &__img-outter,
    &__thermometer-wrapper {
        width: 180px;
        height: 418px;

        @media (min-width: 400px) {
            width: 220px;
            height: 511px;
        }

        @media (--tablet) {
            width: 250px;
            height: 581px;
        }
    }

    & &__thermometer-wrapper {
        position: relative;
    }

    & &__indicator-wrapper {
        position: absolute;
        display: flex;
        flex-direction: column;
        row-gap: 38px;
        width: 52.99px;
        height: 524.9px;
        right: -40px;
        top: 0;
        padding-top: 44px;

        @media (min-width: 400px) {
            right: 0;
        }

        @media (--tablet) {
            row-gap: 42px;
            padding-top: 50px;
            width: 59.76px;
            height: 590.83px;
        }

        @media (--desktop) {
            height: 590.83px;
        }
    }

    & &__indicator {
        background-color: white;
        height: 1px;
        width: 29.42px;
        opacity: 0;
        transform: translateX(70px);
        transition: opacity 0.3s ease-out, transform 0.2s ease-out;

        &--big {
            width: 52.99px;
        }

        &:nth-child(1) {
            transition-delay: 0.1s;
        }

        &:nth-child(2) {
            transition-delay: 0.15s;
        }

        &:nth-child(3) {
            transition-delay: 0.2s;
        }

        &:nth-child(4) {
            transition-delay: 0.25s;
        }

        &:nth-child(5) {
            transition-delay: 0.3s;
        }

        &:nth-child(6) {
            transition-delay: 0.35s;
        }

        &:nth-child(7) {
            transition-delay: 0.4s;
        }

        &:nth-child(8) {
            transition-delay: 0.45s;
        }

        &:nth-child(9) {
            transition-delay: 0.5s;
        }
    }

    &__img-wrapper--visible &__indicator {
        opacity: 1;
        transform: translateX(0);
    }

    & &__celsius {
        color: $white;
        font-size: 28px;
        letter-spacing: 0.05em;
        transition: opacity 2s ease-out;
        transition-delay: 1s;
        font-weight: 700;
        height: auto;
        overflow: hidden;

        @media (min-width: 650px) {
            display: none;
        }

        @media (--desktop) {
            display: block;
            font-size: 55px;
        }
    }

    & &__media-button {
        margin-top: 65px;

        @media (--tablet) {
            margin-top: 50px;
        }
    }

    & &__text {
        margin-top: 35px;
        color: $white;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 22px;
        font-weight: 500;
        max-width: 500px;

        @media (min-width: 650px) {
            /* Relative -> to make the z-index work */
            position: relative;
        }

        @media (--tablet) {
            margin-top: 50px;
            font-size: 20px;
            line-height: 25px;
            max-width: 613px;
        }

        @media (--desktop) {
            font-size: 18px;
            line-height: 23px;
        }
    }

    & &__cta-button {
        margin-top: 40px;

        @media (--tablet) {
            margin-top: 66px;
        }
    }

    @media (min-width: 650px) {
        /* 
      Make relative for absolute positioned thermometer image
      The section has no paddings
       */
        position: relative;
    }

    @media (--desktop) {
        /* 
      On Desktop the Wrapper should position relative, so the image is not going outside of the max-width of the wrapper
       */
        position: static;
    }
}
