.contact-section-failed {
    background-color: $red;
}

.contact-section-successful,
.not-found-section {
    background-color: $black;
}

.contact-section-failed,
.contact-section-successful,
.not-found-section {
    padding-top: 135px;
    padding-bottom: 182px;

    @media (--tablet) {
        padding-top: 134px;
        padding-bottom: 189px;
    }

    @media (--desktop) {
        padding-top: 137px;
        padding-bottom: 230px;
    }

    & &__wrapper {
        max-width: 1280px;
        margin: 0 auto;
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        row-gap: 18px;
        margin-top: 55px;

        @media (--tablet) {
            flex-direction: row;
            justify-content: flex-start;
            column-gap: 70px;
            padding-left: 85px;
            padding-right: 85px;
            margin-top: 53px;
        }

        @media (--desktop) {
            justify-content: flex-start;
            column-gap: 70px;
            padding-left: 90px;
            padding-right: 90px;
        }
    }

    & &__break-section-header-text {
        display: none;
        @media (--desktop) {
            display: block;
        }
    }
}
