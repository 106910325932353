.cta-button {
    background-color: transparent;
    border: none;
    position: relative;
    height: 59px;
    width: 310px;
    display: grid;
    place-items: center;
    cursor: pointer;
    text-decoration: none;

    &__banner {
        width: 100%;
        height: 40px;

        @media (--tablet) {
            width: 240px;
        }
    }

    &__img-hover {
        display: none;
    }

    & &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &:hover &_img {
        display: none;
    }

    &:hover &__img-hover {
        display: block;
    }

    & &__text {
        position: relative;
        z-index: 1;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.2em;
        line-height: 16px;
        color: $black;

        @media (--tablet) {
            font-size: 14px;
            line-height: 18px;
        }
    }

    &:hover &__text {
        color: $white;
    }

    &--white:hover &__text {
        color: $black;
    }

    &--white &__text {
        color: $white;
    }

    /* @media (--tablet) {
        height: 53.34px;
        width: 288.42px;
    } */
}
