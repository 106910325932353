.section-header {
    @media (--desktop) {
        max-width: 1280px;
        margin: 0 auto;
    }

    & &__title {
        color: $red;
        font-size: 28px;
        letter-spacing: 0.05em;
        font-style: italic;
        font-weight: 500;
        text-transform: uppercase;
        padding-left: 40px;
        padding-right: 40px;

        @media (--tablet) {
            font-size: 55px;
            padding-left: 85px;
            padding-right: 85px;
        }

        @media (--desktop) {
            padding-left: 90px;
            padding-right: 90px;
        }

        &--break-desktop-only {
            display: none;
            @media (--desktop) {
                display: block;
            }
        }
    }

    & &__secondary-content-wrapper {
        margin-top: 26px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        column-gap: 10px;
        padding-left: 11px;
        padding-right: 40px;

        @media (--tablet) {
            margin-top: 18px;
            column-gap: 24px;
            padding-left: 34px;
            padding-right: 85px;
        }

        @media (--desktop) {
            margin-top: 18px;
            padding-right: 90px;
        }
    }

    & &__separator {
        border: none;
        background-color: $black;
        height: 1px;
        width: 60px;

        @media (--tablet) {
            width: 142px;
            margin-top: 15px;
        }
    }

    & &__text {
        color: $black;
        font-size: 12px;
        letter-spacing: 0.2em;
        line-height: 16px;
        font-style: italic;
        font-weight: 500;
        flex-grow: 1;

        span {
            display: block;
        }

        @media (--tablet) {
            font-size: 20px;
            line-height: 26px;
        }
    }

    /* Hide */
    &&--hidden {
        display: none;
    }

    /* White Title */
    &&--white-title &__title {
        color: $white;
    }

    /* Black Title */
    &&--black-title &__title {
        color: $black;
    }

    /* Large Title */
    &&--large-title &__title {
        font-size: 28px;
        font-weight: 700;
        font-style: normal;
        line-height: 28px;

        @media (--tablet) {
            font-size: 60px;
            line-height: 60px;
        }

        @media (--desktop) {
            font-size: 80px;
            line-height: 80px;
        }
    }

    /* White Text */
    &&--white-text &__separator {
        background-color: $white;
    }

    &&--white-text &__text {
        color: $white;
    }

    /* Align right */
    &&--align-right {
        @media (--desktop) {
            text-align: right;
        }
    }

    &&--align-right &__separator {
        @media (--desktop) {
            order: 2;
        }
    }

    &&--align-right &__secondary-content-wrapper {
        @media (--desktop) {
            padding-left: 85px;
            padding-right: 34px;
            justify-content: flex-end;
        }
    }

    /* Animation */
    &&--animate &__text {
        opacity: 0;
    }

    &&--animate &__separator {
        transform: translateX(-60%);
        opacity: 0;
    }

    &&--animate&--align-right &__separator {
        transform: translateX(-60%);
        opacity: 0;
        @media (--desktop) {
            transform: translateX(60%);
        }
    }

    & &__text {
        transition: opacity 2s ease-out;
        opacity: 1;
    }

    & &__separator {
        transition: transform 1s ease-out, opacity 1s ease-out;
        opacity: 1;
    }
}
