.history-section {
    background-color: $black;
    color: $white;

    &__wrapper {
        padding: 77px 40px 43px 40px;

        @media (--tablet) {
            padding: 102px 85px 75px 85px;
        }

        @media (--desktop) {
            padding: 146px 90px 189px 90px;
            max-width: 1280px;
            margin: 0 auto;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__header-left {
        display: flex;
        column-gap: 6.6px;

        @media (--tablet) {
            column-gap: 19px;
        }

        @media (--desktop) {
            column-gap: 10px;
        }
    }

    &__header-left img {
        height: 31.12px;
        width: 51.65px;

        @media (--tablet) {
            height: 52.41px;
            width: 87px;
        }
    }

    &__header-left h1,
    &__header-right h2 {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 0.05em;
        color: $white;
        text-transform: uppercase;

        @media (--tablet) {
            font-size: 55px;
        }
    }

    &__header-right h2 {
        display: none;

        @media (--desktop) {
            display: block;
            width: 174px;
            text-align: center;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 30px;
        position: relative;

        @media (--tablet) {
            padding-top: 66px;
        }

        @media (--desktop) {
            align-items: start;
            padding-bottom: 100px;
        }
    }

    &__line {
        position: absolute;
        background-color: $white;
        width: 1px;
        top: 100px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        @media (--desktop) {
            left: auto;
            transform: translateX(0);
            right: 94px;
        }
    }

    &__pointer {
        height: 23px;
        width: 23px;
        background-color: $black;
        border: 1px solid $white;
        border-radius: 50%;
        position: absolute;
        display: none;
        /* z-index: 9999; */
    }

    &__history-partial-wrapper {
        width: 100%;
        /* position: relative; */
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (--desktop) {
            display: block;
        }
    }

    &__history-title-wrapper {
        display: inline-block;
        position: relative;
        z-index: 1;
        width: 188px;
        display: grid;
        place-items: center;

        @media (--desktop) {
            position: absolute;
            right: 0px;
            margin: 0;
            padding-top: 18px;
        }

        &-first {
            display: none;
            padding-top: 0;

            @media (--desktop) {
                display: block;
            }

            p {
                background-color: $white !important;
                color: $black;
            }
        }

        p {
            margin: 72px 0px;
            transition: color 0.5s ease-out, background-color 0.5s ease-out;
            text-align: center;
            border: 1px solid $white;
            background-color: $black;
            padding: 10px 16px;
            font-size: 12px;
            letter-spacing: 0.2em;
            font-weight: 700;

            @media (--tablet) {
                font-size: 14px;
            }

            @media (--desktop) {
                margin: 0;
            }
        }
    }

    &__history-text {
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        letter-spacing: 0.05em;
        position: relative;
        background-color: $black;
        z-index: 1;
        padding-top: 18px;
        padding-bottom: 18px;

        @media (--tablet) {
            font-size: 20px;
            line-height: 25px;
        }

        @media (--desktop) {
            max-width: 613px;
            font-size: 18px;
            line-height: 22px;
        }

        &-first {
            padding-top: 0px;
        }
    }

    &__history-year-title {
        display: none;

        @media (--desktop) {
            font-size: 55px;
            position: absolute;
            right: 0px;
            text-align: center;
            width: 174px;
            height: 200px;
            transform: translateY(-155px);
            background-color: $black;
            display: grid;
            place-items: center;
        }
    }
}
