.privacy-policy-section {
    & &__wrapper {
        max-width: 1280px;
        margin: 0 auto;

        padding: 76px 40px 110px 40px;

        @media (--tablet) {
            padding: 94px 85px 176px 85px;
        }

        @media (--desktop) {
            padding: 140px 90px 352px 90px;
        }
    }

    & &__header {
        display: flex;
        column-gap: 14px;
        align-items: center;

        @media (--tablet) {
            column-gap: 22px;
        }
    }

    & &__title {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 0.05em;
        text-transform: uppercase;

        @media (--tablet) {
            font-size: 55px;
        }
    }

    & &__img {
        width: 40.05px;
        height: 38px;

        @media (--tablet) {
            width: 74.62px;
            height: 70.79px;
        }
    }

    & &__content-wrapper {
        margin-top: 26px;
        display: flex;
        flex-direction: column;
        row-gap: 70px;

        @media (--tablet) {
            margin-top: 56px;
        }
    }

    & &__section-title {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.2em;
        line-height: 16px;

        @media (--tablet) {
            font-size: 18px;
            line-height: 23px;
        }
    }

    & &__blocks-wrapper {
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        font-size: 16px;
        line-height: 20px;

        @media (--tablet) {
            font-size: 20px;
            line-height: 25px;
        }
    }

    & &__block {
        &-title {
            text-transform: uppercase;
            margin-bottom: 4px;

            @media (--tablet) {
                margin-bottom: 6px;
            }
        }

        &-text {
            font-style: italic;
        }
    }
}
