.ventilation-section {
    background-color: $black-2;
    overflow: hidden;

    & &__inner-wrapper {
        padding: 50px 40px 90px 40px;

        @media (--tablet) {
            padding: 90px 85px 141px 85px;
        }

        @media (--desktop) {
            padding-bottom: 140px;
            max-width: 1280px;
            margin: 0 auto;

            display: flex;
            flex-direction: column;
            align-items: flex-end;

            padding-right: 125px;
        }
    }

    & &__subtitle {
        font-size: 28px;
        letter-spacing: 0.05em;
        line-height: 32px;
        font-weight: 700;
        color: $white;
        text-transform: uppercase;

        @media (--tablet) {
            font-size: 55px;
            line-height: 57px;
        }
    }

    & &__img {
        position: relative;
        margin-top: 74px;
        width: 337.97px;
        height: 343.92px;
        left: 50%;
        opacity: 0;
        transform: translateX(-50%);
        transition: opacity 2s ease-out;

        &--visible {
            opacity: 1;
            /* transform: translateX(-50%) rotate(-250deg); */
        }

        @media (min-width: 650px) {
            margin-top: 0;
            width: 606.42px;
            height: 618.86px;
            position: absolute;
            right: -45%;
            top: 50%;
            transform: translateY(-50%);

            &--visible {
                opacity: 0.2;
                /* transform: translateY(-50%) rotate(-250deg); */
            }
        }

        @media (--tablet) {
            right: -25%;
            width: 706.42px;
            height: 718.86px;
        }

        @media (--desktop) {
            width: 706.42px;
            height: 718.86px;
            left: calc(50% - 850px);
            transform: translateY(0);
            top: -50px;

            &--visible {
                opacity: 1;
                /* transform: translateY(0) rotate(-250deg); */
            }
        }
    }

    & &__media-button {
        margin-top: 65px;

        @media (--tablet) {
            margin-top: 50px;
        }
    }

    & &__text {
        margin-top: 35px;
        color: $white;
        font-size: 16px;
        letter-spacing: 0.05em;
        line-height: 22px;
        font-weight: 500;
        max-width: 500px;

        @media (min-width: 650px) {
            /* Relative -> to make the z-index work */
            position: relative;
        }

        @media (--tablet) {
            margin-top: 50px;
            font-size: 20px;
            line-height: 25px;
            max-width: 613px;
        }

        @media (--desktop) {
            font-size: 18px;
            line-height: 23px;
        }
    }

    & &__cta-button {
        margin-top: 40px;

        @media (--tablet) {
            margin-top: 66px;
        }
    }

    @media (min-width: 650px) {
        /* 
    Make relative for absolute positioned ventilation image
    The section has no paddings
     */
        position: relative;
    }
}
