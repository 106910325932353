.video-player {
    display: none;
    position: fixed;
    background-color: rgba(255, 255, 255, 0.9);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    place-items: center;

    padding-left: 10px;
    padding-right: 10px;

    @media (--tablet) {
        padding-left: 50px;
        padding-right: 50px;
    }

    &--visible {
        display: grid;
    }

    &--no-video &__wrapper {
        display: none;
    }

    &--no-video {
        background-color: $black;
        place-items: initial;
        justify-content: center;
    }

    &__wrapper {
        max-width: 1280px;
        width: 100%;
        position: relative;
    }

    &__no-video-wrapper {
        max-width: 1280px;
        width: 100%;
        position: relative;
        display: grid;
        place-items: center;
    }

    &__no-video-close {
        position: absolute;
        top: 65px;
        height: 27.18px;
        width: 27.18px;
        top: 65px;
        right: 10px;
        cursor: pointer;
    }

    &__close {
        position: absolute;
        right: 0;
        top: -50px;
        height: 27.18px;
        width: 27.18px;
        cursor: pointer;
    }

    &__video {
        width: 100%;
    }
}
