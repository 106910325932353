.sanitary-section {
    position: relative;

    & &__title-swiper-desktop {
        display: none;

        @media (--desktop) {
            display: block;

            margin-top: 85px;
            padding-left: 45px;
            padding-right: 45px;

            max-width: 1280px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    /* Title Swiper */
    & &__title-swiper-wrapper {
        position: relative;
        margin-top: 50px;
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 50px;
        padding-right: 50px;

        @media (min-width: --tablet) {
            margin-top: 86px;
            margin-left: 30px;
            margin-right: 30px;
            padding-left: 80px;
            padding-right: 80px;
        }

        @media (--desktop) {
            display: none;
        }
    }

    & &__title-swiper {
        overflow: hidden;
    }

    & &__title-swiper .swiper-slide {
        display: flex;
        justify-content: center;

        @media (min-width: 550px) {
            justify-content: flex-start;
        }

        @media (--tablet) {
            padding-left: 19px;
            padding-right: 19px;
        }

        &__title {
            font-size: 28px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            @media (--tablet) {
                font-size: 55px;
                font-weight: 700;
                line-height: 57px;
            }
        }
    }

    .swiper-button-wrapper img:last-child {
        display: none;
    }

    .swiper-button-wrapper:hover img:not(.swiper-button-disabled):last-child {
        @media (--desktop) {
            display: block;
        }
    }

    .swiper-button-wrapper:hover img:not(.swiper-button-disabled):first-child {
        @media (--desktop) {
            display: none;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        height: 25.07px;
        width: 16.87px;
        top: 50%;
        transform: translateY(50%);

        @media (--tablet) {
            height: 55.62px;
            width: 37.44px;
            top: 20%;
        }

        @media (--desktop) {
            height: 55.62px;
            width: 37.44px;
            top: 20%;
        }
    }

    /* Content Swiper */
    & &__content-swiper-wrapper {
        margin-top: 37px;

        @media (min-width: 834px) {
            margin-top: 41px;
        }
    }

    & &__content-swiper .swiper-slide {
        padding-bottom: 150px;
        height: auto;
        padding-bottom: 150px;
        overflow: hidden;

        @media (--desktop) {
            overflow: visible;
        }

        &--hide-img .swiper-slide__background {
            opacity: 0;
        }

        &__wrapper {
            position: relative;

            max-width: 1280px;
            margin-left: auto;
            margin-right: auto;

            padding-left: 40px;
            padding-right: 40px;

            @media (min-width: 834px) {
                padding-left: 85px;
                padding-right: 85px;
            }

            @media (--desktop) {
                padding-left: 85px;
                padding-right: 85px;
            }
        }

        &__text {
            margin-top: 35px;
            font-size: 16px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.05em;

            @media (min-width: 834px) {
                margin-top: 50px;
                font-size: 20px;
                line-height: 25px;
            }

            @media (--tablet) {
                max-width: 613px;
            }

            @media (--desktop) {
                margin-top: 50px;
                font-size: 18px;
                line-height: 23px;
            }
        }

        &__cta-button-wrapper {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            margin-top: 40px;

            @media (--tablet) {
                flex-direction: row;
                column-gap: 20px;
                margin-top: 66px;
            }

            @media (--desktop) {
                margin-top: 66px;
            }
        }

        &__background {
            position: absolute;
            height: 556px;
            bottom: 0px;
            right: 0%;
            opacity: 0.5;
            z-index: -1;
            transition: opacity 0.4s;

            @media (-tablet) {
                opacity: 0.6;
            }

            @media (--desktop) {
                opacity: 1;
            }
        }

        &__background-water-conditioning {
            right: -80px;
            height: 450px;

            @media (--tablet) {
                height: 550px;
            }

            @media (--desktop) {
                height: 100%;
                height: 700px;
            }
        }

        &__background-individual-baths,
        &__background-accessible-baths {
            right: 0;

            @media (--tablet) {
                height: 550px;
            }

            @media (--desktop) {
                height: 100%;
                height: 700px;
            }
        }
    }

    & &__content-swiper .swiper-pagination {
        bottom: 50px;
    }

    & &__content-swiper .swiper-pagination-bullet {
        border: 1px solid $black;
        background-color: transparent;
        height: 9px;
        width: 9px;
        opacity: 1;
    }

    & &__content-swiper .swiper-pagination-bullet-active {
        background-color: $black;
    }
}
