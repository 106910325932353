.radiator-section {
    background-color: $black;
    overflow: hidden;

    /* For the pagination */
    position: relative;

    @media (--desktop) {
        padding-top: 89px;
    }

    /* Illustrations Swiper */
    & &__illustrations-wrapper {
        position: relative;
        margin-top: 56px;
        margin-left: 23px;
        margin-right: 23px;
        padding-left: 57px;
        padding-right: 57px;

        @media (--tablet) {
            margin-top: 93px;
            margin-left: 85px;
            margin-right: 85px;
            padding-left: 0px;
            padding-right: 0px;
        }

        @media (--desktop) {
            max-width: 1280px;
            margin: 69px auto 0px auto;
            padding-left: 90px;
            padding-right: 90px;
        }
    }

    & .swiper-button-prev,
    & .swiper-button-next {
        height: 25.07px;
        width: 16.87px;
        top: 50%;
        transform: translateY(50%);
    }

    & .swiper-button-disabled {
        display: none;
    }

    & &__illustrations-swiper {
        overflow: hidden;
    }

    & &__illustrations-swiper .swiper-wrapper {
        align-items: center;
        justify-content: space-between;
    }

    & &__illustrations-swiper .swiper-slide {
        overflow: hidden;
        display: flex;
        justify-content: center;
        width: auto;
        cursor: pointer;

        img {
            height: auto;
            width: auto;
            max-height: 56px;
            max-width: 56px;
        }

        &:hover img:last-child {
            display: block;
        }

        &:hover img:first-child {
            display: none;
        }

        &:not(:hover):not(.swiper-slide--active) img:last-child {
            display: none;
        }

        &.swiper-slide--active img:first-child {
            display: none;
        }

        @media (--tablet) {
            img {
                max-height: 102px;
                max-width: 94px;
            }
        }

        @media (--desktop) {
            img {
                max-height: 110px;
                max-width: 150px;
            }
        }
    }

    /* Heating Types Swiper */
    & &__heating-types-wrapper {
        margin-top: 63px;
        overflow: hidden;

        @media (--tablet) {
            margin-top: 100px;
        }
    }

    & &__heating-types-wrapper .swiper-wrapper {
        align-items: stretch;
    }

    & &__heating-types-swiper .swiper-slide {
        overflow: hidden;
        height: auto;
        transition: opacity 1s ease-in-out;
        /* For the image of every slide */
        position: relative;

        &--visible {
            opacity: 1;
        }

        &__wrapper {
            padding-bottom: 150px;
            padding-left: 40px;
            padding-right: 40px;

            @media (--tablet) {
                padding-left: 85px;
                padding-right: 85px;
            }

            @media (--desktop) {
                max-width: 1280px;
                margin: 0 auto;
            }
        }

        &__img {
            position: absolute;
            width: 876px;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0.5;
            z-index: -1;

            @media (--tablet) {
                width: 1225px;
                left: 0;
                transform: none;
            }

            @media (--desktop) {
                width: 1172px;
                right: 0;
                left: auto;
                opacity: 1;
            }
        }

        &--left .swiper-slide__title,
        &--left .swiper-slide__media-button,
        &--left .swiper-slide__text,
        &--left .swiper-slide__cta-button,
        &--left .swiper-slide__cta-partner {
            transform: translateX(-100px);
            opacity: 0;

            @media (--tablet) {
                transform: translateX(-200px);
            }
        }

        &--right .swiper-slide__title,
        &--right .swiper-slide__media-button,
        &--right .swiper-slide__text,
        &--right .swiper-slide__cta-button,
        &--right .swiper-slide__cta-partner {
            transform: translateX(100px);
            opacity: 0;

            @media (--tablet) {
                transform: translateX(200px);
            }
        }

        &__title,
        &__media-button,
        &__text,
        &__cta-button,
        &__cta-partner {
            transform: translateX(0);
            opacity: 1;
            transition: opacity 0.4s ease-out, transform 0.4s ease-out;
        }

        &__title {
            transition-delay: 0s;
        }

        &__media-button {
            transition-delay: 0.1s;
        }

        &__text {
            transition-delay: 0.2s;
        }

        &__cta-button {
            transition-delay: 0.3s;
        }

        &__cta-partner {
            transition-delay: 0.4s;
        }

        &__title {
            color: $white;
            font-size: 28px;
            letter-spacing: 0.05em;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 35px;

            @media (--tablet) {
                font-size: 55px;
                line-height: 70px;
            }
        }

        &__video-button {
            @media (--tablet) {
                margin-top: 50px;
            }
        }

        &__media-button {
            margin-top: 32px;
        }

        &__text {
            color: $white;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.05em;
            margin-top: 35px;
            line-height: 22px;

            @media (--tablet) {
                max-width: 700px;
                font-size: 20px;
                line-height: 25px;
                margin-top: 50px;
            }

            @media (--desktop) {
                font-size: 18px;
                line-height: 23px;
                max-width: 613px;
            }
        }

        &__cta-button-wrapper {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            margin-top: 40px;

            @media (--tablet) {
                flex-direction: row;
                column-gap: 20px;
                margin-top: 50px;
            }

            @media (--desktop) {
                margin-top: 66px;
            }
        }
    }

    & &__heating-types-swiper .swiper-pagination {
        bottom: 50px;

        @media (min-width: 1280px) {
            bottom: 38px;
        }
    }

    & &__heating-types-swiper .swiper-pagination-bullet {
        border: 1px solid $white;
        background-color: transparent;
        height: 9px;
        width: 9px;
        opacity: 1;
    }

    & &__heating-types-swiper .swiper-pagination-bullet-active {
        background-color: $white;
    }
}
