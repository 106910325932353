.media-button {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    column-gap: 14.8px;
    cursor: pointer;
    padding-left: 0;
    padding-right: 0;
    color: $black;

    @media (--tablet) {
        column-gap: 22px;
    }

    &:hover {
        color: $red;
    }

    & &__img {
        width: 25.17px;
        height: 25.17px;

        @media (--tablet) {
            width: 56px;
            height: 56px;
        }
    }

    & &__text {
        white-space: nowrap;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.2em;
        line-height: 16px;

        @media (--tablet) {
            font-size: 18px;
            line-height: 23px;
        }
    }

    &--white {
        color: $white;
    }
}
