.header {
    width: 100%;
    position: relative;
    overflow: hidden;

    &--white {
        background-color: #fff;
    }

    &--black {
        background-color: $black;
    }

    &--red {
        background-color: $red;
    }

    &--lean &__video-background {
        display: none;
    }

    &--lean &__center-wrapper {
        display: none;
    }

    &--lean &__bottom-wrapper {
        display: none;
    }

    &--lean &__nav {
        display: none;
    }

    &--lean &__mobile-menu-open {
        display: none;
    }

    &--lean &__wrapper {
        max-width: none;

        @media (--tablet) {
            max-width: none;
        }
    }

    &__video-background {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        bottom: 0;
        height: 100%;
        transform: translateX(-50%);

        @media (min-width: 1450px) {
            left: 0;
            height: auto;
            width: 100%;
            transform: translateX(0);
        }

        &--hidden {
            display: none;
        }
    }

    &__image-background {
        z-index: -1;
        display: none;
        position: absolute;
        top: 0;
        left: 50%;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        transform: translateX(-50%);
    }

    &__image-background--visible {
        display: block;
    }

    /* Header Wrapper */
    &__wrapper {
        max-width: 500px;
        margin: 0 auto;

        @media (--tablet) {
            max-width: 1080px;
            margin: 0 auto;
        }

        @media (--desktop) {
            max-width: 1280px;
            margin: 0 auto;
        }
    }

    /* Top Wrapper */
    &__top-wrapper {
        margin: 0 auto;
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: end;

        @media (--tablet) {
            padding: 85px 85px 0px 85px;
        }

        @media (--desktop) {
            padding: 90px 90px 0px 90px;
            max-width: 1280px;
        }
    }

    &__logo-wrapper {
        width: 163px;
        height: 77px;

        @media (--tablet) {
            width: 264px;
            height: 125px;
        }

        @media (--desktop) {
            width: 264px;
            height: 125px;
        }
    }

    &__logo,
    &__logo-hover {
        height: 100%;
        width: 100%;
    }

    & &__logo-hover {
        display: none;
    }

    &__logo-wrapper:not(.header__logo-wrapper--no-hover):hover &__logo-hover {
        display: block;
    }

    &__logo-wrapper:not(.header__logo-wrapper--no-hover):hover &__logo {
        display: none;
    }

    &__mobile-menu-open {
        height: 46.18px;
        width: 46.18px;
        cursor: pointer;

        @media (--tablet) {
            height: 71.02px;
            width: 71.02px;
        }

        @media (--desktop) {
            display: none;
        }
    }

    &__nav {
        display: none;

        @media (--desktop) {
            display: block;
            display: flex;
            column-gap: 25px;
        }

        &-link {
            @media (--desktop) {
                font-weight: 700;
                color: $black;
                font-size: 13px;
                letter-spacing: 0.2em;
                text-decoration: none;

                &:hover {
                    color: $red;
                    font-style: italic;
                }
            }
        }
    }

    /* Center Wrapper */
    &__center-wrapper {
        margin-top: 143px;

        @media (--tablet) {
            margin-top: 295px;
        }

        @media (--desktop) {
            margin: 110px auto;
        }
    }

    &__title {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 0.05em;
        line-height: 28px;
        padding-left: 40px;
        padding-right: 40px;

        @media (--tablet) {
            font-size: 60px;
            line-height: 60px;
            padding-left: 85px;
            padding-right: 85px;
        }

        @media (--desktop) {
            font-size: 80px;
            line-height: 80px;
            padding-left: 90px;
            padding-right: 90px;
        }
    }

    &__keywords-wrapper {
        margin-top: 10px;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        column-gap: 13px;
        padding-left: 11px;

        @media (--tablet) {
            margin-top: 24px;
            column-gap: 26px;
            padding-left: 26px;
        }

        @media (--desktop) {
            margin-top: 19px;
        }
    }

    &__separator {
        border: none;
        background-color: $black;
        height: 1px;
        width: 68.89px;

        @media (--tablet) {
            width: 142.5px;
        }

        @media (--desktop) {
            width: 142.5px;
        }
    }

    &__keywords {
        font-size: 12px;
        font-style: italic;
        font-weight: 500;
        letter-spacing: 0.2em;
        line-height: 16px;

        @media (--tablet) {
            font-size: 20px;
            line-height: 26px;
        }

        @media (--desktop) {
            font-size: 20px;
            line-height: 26px;
        }
    }

    /* Bottom Wrapper */
    &__bottom-wrapper {
        margin-top: 180px;
        margin-bottom: 50px;
        display: flex;
        justify-content: space-between;
        padding-left: 40px;
        padding-right: 40px;

        @media (--tablet) {
            margin-top: 314px;
            margin-bottom: 89px;
            padding-left: 85px;
            padding-right: 85px;
        }

        @media (--desktop) {
            margin: 152px auto;
            margin-bottom: 67px;
            justify-content: flex-end;
            column-gap: 52px;
        }
    }

    &__illustration-wrapper {
        @media (--desktop) {
            display: flex;
            align-items: center;
            column-gap: 14px;
            color: $black;
            text-decoration: none;
        }
    }

    &__illustration-wrapper:hover {
        color: $red;
    }

    &__illustration-wrapper:hover &__illustration {
        filter: invert(16%) sepia(90%) saturate(4361%) hue-rotate(349deg)
            brightness(85%) contrast(111%);
    }

    &__illustration {
        height: 52px;

        @media (--tablet) {
            height: 100px;
        }

        @media (--desktop) {
            height: 50px;
        }
    }

    &__illustration-info {
        display: none;
        white-space: nowrap;

        @media (--desktop) {
            display: block;
            letter-spacing: 0.2em;
            font-size: 11px;
            line-height: 15px;
        }
    }

    &__illustration-title {
        @media (--desktop) {
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    &__illustration-text {
        @media (--desktop) {
            font-style: italic;
        }
    }
}
