.funding-section {
    padding-bottom: 90px;

    @media (--desktop) {
        position: relative;
    }

    & &__text-wrapper {
        padding-top: 50px;
        padding-left: 40px;
        padding-right: 40px;

        @media (--tablet) {
            padding-left: 85px;
            padding-right: 85px;
        }

        @media (--desktop) {
            padding-left: 577px;
        }

        @media (--desktop) {
            padding-top: 90px;
            max-width: 1280px;
            margin: 0 auto;
        }
    }

    & &__subtitle {
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 0.05em;
        line-height: 32px;
        text-transform: uppercase;

        @media (--tablet) {
            font-size: 55px;
            line-height: 57px;
        }
    }

    & &__link {
        margin-top: 40px;
        display: flex;
        align-items: center;
        column-gap: 9px;
        font-size: 12px;
        letter-spacing: 0.2em;
        line-height: 16px;
        font-weight: 700;
        text-decoration: none;
        color: $black;

        &:hover {
            color: $red;
        }

        @media (--tablet) {
            column-gap: 31px;
            font-size: 18px;
            line-height: 23px;
        }
    }

    & &__link-img {
        width: 31px;
        height: 17px;

        @media (--tablet) {
            width: 61px;
            height: 34px;
        }
    }

    & &__text {
        margin-top: 40px;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;
        font-weight: 500;

        @media (--tablet) {
            font-size: 20px;
            line-height: 25px;
            max-width: 613px;
        }

        @media (--desktop) {
            font-size: 18px;
            line-height: 23px;
        }
    }

    & &__img-wrapper {
        margin-top: 43px;
        display: flex;
        align-items: center;
        column-gap: 22px;
        position: relative;
        left: -20px;

        @media (--tablet) {
            margin-top: 100px;
            column-gap: 45px;
            left: -7px;
        }

        @media (--desktop) {
            position: absolute;
            top: 47%;
            transform: translateY(-50%);

            left: calc(50% - 650px);
        }
    }

    & &__heating-wrapper {
        position: relative;
        height: 254.99px;
        width: 85.28px;
        z-index: 999;

        @media (--tablet) {
            width: 175.51px;
            height: 524.8px;
        }
    }

    & &__indicator-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        position: absolute;
        bottom: 2px;
        right: 23px;

        @media (--tablet) {
            row-gap: 25px;
            bottom: 4.2px;
            right: 48.2px;
        }
    }

    & &__indicator {
        width: 9.41px;
        height: 1.61px;
        background-color: $black;

        opacity: 0;
        transform: translateX(-60px);
        transition: 0.3s opacity ease-out, 0.6s transform ease-out;

        @media (--tablet) {
            width: 19.38px;
            height: 3.31px;
        }

        &:nth-child(4),
        &:nth-child(11),
        &:nth-child(12) {
            width: 15.3px;
            height: 2.62px;

            @media (--tablet) {
                width: 31.49px;
                height: 5.39px;
            }
        }

        &:nth-child(8) {
            margin-top: 15.6px;

            @media (--tablet) {
                margin-top: 30.8px;
            }
        }

        &:nth-child(12) {
            margin-top: 41px;

            @media (--tablet) {
                margin-top: 83px;
            }
        }

        &:nth-child(1) {
            transition-delay: 1s;
        }

        &:nth-child(2) {
            transition-delay: 1.1s;
        }

        &:nth-child(3) {
            transition-delay: 1.2s;
        }

        &:nth-child(4) {
            transition-delay: 1.3s;
        }

        &:nth-child(5) {
            transition-delay: 1.4s;
        }

        &:nth-child(6) {
            transition-delay: 1.5s;
        }

        &:nth-child(7) {
            transition-delay: 1.6s;
        }

        &:nth-child(8) {
            transition-delay: 1.7s;
        }

        &:nth-child(9) {
            transition-delay: 1.8s;
        }

        &:nth-child(10) {
            transition-delay: 1.9s;
        }

        &:nth-child(11) {
            transition-delay: 2s;
        }

        &:nth-child(12) {
            transition-delay: 2.1s;
        }
    }

    & &__img-wrapper--visible &__indicator {
        opacity: 1;
        transform: translateX(0);
    }

    & &__numbers-img {
        width: 12.31px;
        height: 230.95px;
        position: absolute;
        right: 0px;
        bottom: 0;

        @media (--tablet) {
            width: 25.33px;
            height: 475.32px;
        }
    }

    & &__red-triangle-big-img {
        position: absolute;
        height: 61px;
        width: 61px;
        right: 24px;
        z-index: 999;
        opacity: 0;
        transition: 1s opacity ease-out;
        transition-delay: 0.4s;

        top: 100%;
        transform: translateY(-55%);

        @media (--tablet) {
            width: 126px;
            height: 125px;
            right: 49px;
        }
    }

    & &__img-wrapper--visible &__red-triangle-big-img {
        opacity: 1;
    }

    & &__red-triangle-img,
    & &__yellow-triangle-img {
        position: absolute;
        width: 11.61px;
        height: 27.46px;
        right: 0px;
        opacity: 0;
        transform: translateX(-50px);
        transition: 1s opacity ease-out, 1s transform ease-out;

        @media (--tablet) {
            width: 23.9px;
            height: 56.51px;
            right: 9.4px;
        }
    }

    & &__red-triangle-img {
        bottom: 129px;

        @media (--tablet) {
            bottom: 265.5px;
        }
    }

    & &__yellow-triangle-img {
        bottom: 72px;

        @media (--tablet) {
            bottom: 148.8px;
        }
    }

    & &__img-wrapper--visible &__red-triangle-img,
    & &__img-wrapper--visible &__yellow-triangle-img {
        opacity: 1;
        transform: translateX(0);
        transition-delay: 0.4s;
    }

    & &__img-wrapper--visible &__yellow-triangle-img {
        transition-delay: 0.6s;
    }

    & &__sun-img {
        position: absolute;
        width: 17.67px;
        height: 17.66px;
        right: 23.4px;
        bottom: 106px;
        opacity: 0;
        transform: translateX(-100px);
        transition: 1s opacity ease-out, 1s transform ease-out;

        @media (--tablet) {
            width: 36.36px;
            height: 36.35px;
            bottom: 218.2px;
            right: 48.2px;
        }
    }

    & &__img-wrapper--visible &__sun-img {
        opacity: 1;
        transform: translateX(0);
        transition-delay: 0.4s;
    }

    & &__moon-img {
        position: absolute;
        width: 11.59px;
        height: 7.51px;
        right: 4px;
        bottom: 28px;

        @media (--tablet) {
            width: 23.85px;
            height: 15.46px;
            bottom: 57.4px;
            right: 8px;
        }
    }

    & &__house-img {
        width: 261.24px;
        height: 146.6px;
        opacity: 0;
        transition: 1s opacity ease-out;
        transform: scale(0.7);

        @media (--tablet) {
            width: 620.58px;
            height: 349.47px;
        }

        @media (--desktop) {
            position: absolute;

            transform: scale(0.7) translateX(-110px);
        }
    }

    & &__img-wrapper--visible &__house-img {
        opacity: 1;
    }

    & &__cta-wrapper {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 18px;

        @media (min-width: 700px) {
            flex-direction: row;
            justify-content: center;
            column-gap: 37px;
        }

        @media (--tablet) {
            flex-direction: row;
            justify-content: center;
            column-gap: 37px;
            margin-top: 88px;
        }

        @media (--desktop) {
            max-width: 1280px;
            margin-top: 65px;
            margin-left: auto;
            margin-right: auto;
            justify-content: flex-start;
            padding-left: 577px;
        }
    }
}
