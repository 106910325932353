.select {
    border-bottom: 1px solid $black;
    position: relative;

    &__selected {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px 10px 34px;
        cursor: pointer;
        user-select: none;

        &-icon {
            width: 12.24px;
            height: 6.15px;

            &--rotated {
                transform: rotate(180deg);
            }
        }
    }

    &__dropdown {
        position: absolute;
        display: none;
        border: 1px solid $black;
        background-color: white;
        z-index: 999;

        &--active {
            display: block;
            right: 0;
            left: 0;
        }

        &-list {
            display: flex;
            flex-direction: column;

            li {
                padding: 10px 10px 10px 34px;
                user-select: none;

                &:hover {
                    background-color: $black;
                    color: white;
                    cursor: pointer;
                }

                &:not(:first-child) {
                    border-top: 1px solid $black;
                }
            }
        }
    }
}
