section {
    overflow: hidden;
}

section:not(.section-no-default-padding) {
    padding-top: 50px;
}

@media (min-width: 834px) {
    section:not(.section-no-default-padding) {
        padding-top: 89px;
    }
}
