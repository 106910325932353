.team-section {
    background-color: $black;
    color: $white;

    & &__wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 62px;

        max-width: 1280px;
        margin: 0 auto;
        padding: 76px 40px 110px 40px;

        @media (--tablet) {
            padding: 94px 85px 176px 85px;
            row-gap: 90px;
        }

        @media (--desktop) {
            padding: 140px 90px 352px 90px;
            flex-direction: row;
            column-gap: 172px;
        }
    }

    & &__header {
        display: flex;
        column-gap: 17px;
        align-items: center;

        /* Headerquarters img */
        &-img-wrapper {
            overflow: hidden;
            position: relative;
            width: 36.71px;
            height: 36.71px;

            @media (--tablet) {
                width: 72.39px;
                height: 72.39px;
            }
        }

        &-img-wrapper img {
            position: absolute;
            height: 100%;
            width: 100%;
        }

        &-img--animate-outter {
            opacity: 0;
            animation: 1s ease-out outterAnim forwards;
        }

        &-img--animate-inner {
            opacity: 0;
            animation-name: middleInnerAnim;
            animation-duration: 1s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
        }

        &-img-middle,
        &-img-inner {
            left: 0;
        }

        &-img-middle {
            animation-delay: 0.3s;
        }

        &-img-inner {
            animation-delay: 0.5s;
        }

        &-title {
            font-size: 28px;
            font-weight: 700;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            @media (--tablet) {
                font-size: 55px;
            }
        }

        @keyframes outterAnim {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        @keyframes middleInnerAnim {
            0% {
                opacity: 0;
                transform: rotate(20deg);
            }
            100% {
                opacity: 1;
                transform: rotate(0);
            }
        }
    }

    & &__jobs-wrapper {
        margin-top: 26px;

        display: flex;
        flex-direction: column;
        row-gap: 50px;

        @media (--tablet) {
            margin-top: 58px;
            row-gap: 58px;
        }
    }

    & &__job-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }

    & &__job-title {
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.2em;
        line-height: 16px;

        @media (--tablet) {
            font-size: 18px;
            line-height: 23px;
        }
    }

    & &__persons-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }

    & &__person {
        font-size: 16px;
        line-height: 20px;

        @media (--tablet) {
            font-size: 20px;
            line-height: 25px;
        }

        &-name {
            font-weight: 700;
            text-transform: uppercase;
            display: block;
        }

        &-contact-info {
            font-style: italic;
            a {
                color: $white;
                text-decoration: none;
            }
        }
    }
}
