.contact-section {
    padding-top: 97px;

    & &__form {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0px 40px 110px 40px;

        @media (--tablet) {
            padding: 0px 85px 113px 85px;
        }

        @media (--desktop) {
            padding: 0px 90px 125px 90px;
        }
    }

    /* Set all placeholder colors to black */
    ::placeholder {
        color: $black;
        opacity: 1;
    }

    /* Set Font Properties */
    & &__form input,
    & &__form select,
    & &__form label,
    & &__form textarea,
    & &__form &__select {
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.2em;
        border-radius: 0;
        font-style: italic;
        font-weight: 500;

        @media (--tablet) {
            font-size: 18px;
            line-height: 23px;
        }
    }

    /* Disable highlight behaviours */
    & &__form input:focus,
    & &__form select:focus,
    & &__form textarea:focus,
    & &__form button:focus {
        outline: none;
    }

    & &__form input,
    & &__form select {
        background-color: $white;
        border: none;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 34px;
        width: 100%;
    }

    & &__input-border-wrapper {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: -60%;
            opacity: 0;
            height: 1px;
            width: 150%;
            transition: all 1s ease-out;
            background-color: $black;
        }

        &--border-visible::after {
            left: 0;
            opacity: 1;
            width: 100%;
        }
    }

    & &__error-label {
        margin-top: 12px;
        color: $red;
    }

    & &__row-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 44px;

        @media (--tablet) {
            flex-direction: row;
            justify-content: flex-start;
            column-gap: 75px;
        }

        @media (--desktop) {
            column-gap: 90px;
        }
    }

    & &__row-wrapper + &__row-wrapper {
        margin-top: 44px;
    }

    & &__row-item-wrapper {
        @media (--tablet) {
            max-width: 294px;
            width: 100%;

            &--full-width {
                max-width: none;
            }
        }

        @media (--desktop) {
            max-width: 326.5px;

            &--full-width {
                max-width: none;
            }
        }
    }

    & &__selects-wrapper {
        margin-top: 114px;
        display: flex;
        flex-direction: column;
        row-gap: 44px;
    }

    & &__select-wrapper &__select {
        background-color: white;
        border-bottom: 1px solid $black;
        width: 100%;
    }

    & &__customer-type-wrapper {
        display: flex;
        flex-direction: column;
        row-gap: 44px;

        @media (--tablet) {
            flex-direction: row;
            column-gap: 75px;
            align-items: flex-start;
        }
    }

    /* 
    The customer id text field is in default not visible
    */
    & &__customer-id-wrapper {
        display: none;
        width: 100%;
    }

    & input&__customer-id {
        border-bottom: 1px solid $black;
    }

    & &__customer-type-wrapper &__select-wrapper {
        width: 100%;
    }

    /* 
    If the selected customer type is existing customer
    then add a max width to the select
    */
    & &__customer-type-wrapper--existing-customer &__select-wrapper {
        max-width: 294px;

        @media (--desktop) {
            max-width: 326.5px;
        }
    }

    /* 
    If the selected customer type is existing customer
    then show the customer id text field and set a max-width
    */
    & &__customer-type-wrapper--existing-customer &__customer-id-wrapper {
        display: block;
        max-width: 294px;

        @media (--desktop) {
            max-width: 326.5px;
        }
    }

    & &__message-wrapper {
        margin-top: 46px;
        display: flex;
        flex-direction: column;
        row-gap: 13px;

        @media (--tablet) {
            margin-top: 57px;
            row-gap: 10px;
        }
    }

    & &__message-label {
        padding-left: 35px;
    }

    & &__message {
        height: 613.9px;
        resize: none;
        padding: 20px 21px 90px 35px;

        @media (--tablet) {
            height: 549.9px;
            padding-bottom: 26px;
        }

        @media (--desktop) {
            height: 375px;
            padding-bottom: 26px;
        }
    }

    & &__agb-wrapper {
        margin-top: 61px;
        user-select: none;

        :hover {
            color: $red;
        }
    }

    & &__agb-inner-wrapper {
        display: flex;
        column-gap: 20px;
        align-items: flex-start;
        justify-content: flex-start;
    }

    & input&__agb {
        width: auto;
        accent-color: $black;
        border: 1px solid black;
    }

    & &__submit-wrapper {
        margin-top: 52px;

        @media (--tablet) {
            margin-top: 61px;
        }
    }
}
