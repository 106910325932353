.imprint-section {
    background-color: $black;
    color: white;

    & &__wrapper {
        max-width: 1280px;
        margin: 0 auto;

        padding: 76px 40px 110px 40px;

        @media (--tablet) {
            padding: 94px 85px 176px 85px;
        }

        @media (--desktop) {
            padding: 140px 90px 352px 90px;
        }
    }

    & &__header {
        display: flex;
        column-gap: 15px;
        align-items: center;

        @media (--tablet) {
            column-gap: 22px;
        }
    }

    & &__header-title {
        font-size: 28px;
        letter-spacing: 0.05em;
        font-weight: 700;

        @media (--tablet) {
            font-size: 55px;
        }
    }

    & &__header-img {
        width: 40.05px;
        height: 38px;

        @media (--tablet) {
            width: 74.62px;
            height: 70.79px;
        }
    }

    & &__owner {
        margin-top: 26px;

        @media (--tablet) {
            margin-top: 58px;
        }

        &-title {
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.2em;

            @media (--tablet) {
                font-size: 18px;
            }
        }

        &-text {
            margin-top: 16px;
            font-size: 16px;
            line-height: 20px;

            @media (--tablet) {
                margin-top: 15px;
                font-size: 20px;
                line-height: 25px;
            }
        }

        &-text-name {
            text-transform: uppercase;
            font-weight: 700;
            display: block;
        }

        &-text-address {
            font-style: italic;
            color: $white;
            text-decoration: none;
        }
    }

    & &__contact {
        margin-top: 50px;

        &-title {
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.2em;

            @media (--tablet) {
                font-size: 18px;
            }
        }

        &-text {
            margin-top: 16px;
            font-size: 16px;
            line-height: 20px;
            font-style: italic;

            a {
                text-decoration: none;
                color: $white;
            }

            @media (--tablet) {
                margin-top: 15px;
                font-size: 20px;
                line-height: 25px;
            }
        }
    }
}
